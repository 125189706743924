import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    Cell,
    ResponsiveContainer
  } from "recharts";
  
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink", "gray", "yellow","green","blue"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
				backgroundColor: 'white', 
				padding: 6, 
				border: "2px solid #1D8FC8",
				borderRadius: 6,
				fontSize: 14
				}}
			>
				<h6>{payload[0].payload.league_name}</h6> 
				Games: {payload[0].payload.count} <br/>
				Won: {payload[0].payload.win} <br/>
				Lose: {payload[0].payload.lose} <br/>
				Success: {payload[0].payload.success_rate}%
			</div>
    );
  }

  return null;
};
  
  export default function App({data}) {
    return (
      <ResponsiveContainer width="100%" height="100%"> 
        <ScatterChart
          width={400}
          height={400}
          margin={{
            top: 50,
            right: 50,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="success_rate" name="Success Rate" unit="%" domain={[0, 100]}/>
          <YAxis type="number" dataKey="count" name="Games" />
          <ZAxis
            type="number"
            dataKey="win"
            range={[10, 3000]}
            name="Won"
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} content={<CustomTooltip/>}/>
          <Scatter name="A school" data={data} fill="#8884d8">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer> 
    );
  }
  