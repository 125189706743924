import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { visuallyHidden } from '@mui/utils';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function descendingComparator(a, b, orderBy) {
  if (orderBy === 'success_rate') {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  won: {
    color: 'green',
    fontWeight: 700,
  },
  lose: {
    color: 'red',
    fontWeight: 700,
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  tableCell: {
    padding: "6px 0px !important"
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}))(TableRow);

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } =
    props;
  const { lang } = useSelector(state => state.lang);

  const headCells = [
    {
      id: 'game_name',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_gamename || 'Game'
    },
    {
      id: 'game_type',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_gametype || 'Game Type'
    },
    {
      id: 'analyze_type',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_bettype || 'Bet Type'
    },
    {
      id: 'user_analyze_direction',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_betdirection || 'Bet Direction'
    },
    {
      id: 'analyze_line',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_bettingline || 'Betting Line'
    },
    {
      id: 'F9_points',
      numeric: true,
      disablePadding: false,
      label: lang.ca_label_averagediff || 'Average Difference'
    }
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            className={classes.tableCell}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableHeader}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default function EnhancedTable({ tableData }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('game_date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { lang } = useSelector(state => state.lang);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            <TableBody>
              {(rowsPerPage > 0 ?
                stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) :
                stableSort(tableData, getComparator(order, orderBy))
              )
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell align="center" >{row.game_name}</StyledTableCell>
                      <StyledTableCell align="center" >
                        {row.game_type === 0 ? lang.ln_label_allgame || "All Games" :
                          row.game_type === 5 ? lang.ln_label_5game || "5 Games" :
                            row.game_type === 4 ? lang.ln_label_4game || "4 Games" : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center" >
                        {row.analyze_type === 1 ? lang.ca_analyzetype_1 || 'Total Points' :
                          row.analyze_type === 2 ? lang.ca_analyzetype_2 || 'Second Half' :
                            row.analyze_type === 3 ? lang.ca_analyzetype_3 || 'First Half' :
                              row.analyze_type === 4 ? lang.ca_analyzetype_4 || 'Forth Quarter' :
                                row.analyze_type === 5 ? lang.ca_analyzetype_5 || 'Third Quarter' :
                                  row.analyze_type === 6 ? lang.ca_analyzetype_6 || 'Second Quarter' :
                                    row.analyze_type === 7 ? lang.ca_analyzetype_7 || 'First Quarter' : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center" >
                        {row.user_analyze_direction === 0 ? lang.ca_label_all || 'ALL' :
                          row.user_analyze_direction === -1 ? lang.ca_label_under || 'UNDER' :
                            row.user_analyze_direction === 1 ? lang.ca_label_over || 'OVER' : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center" >{row.analyze_line}</StyledTableCell>
                      <StyledTableCell align="center" >{row.F9_points}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, { label: 'All', value: -1 }]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
}
