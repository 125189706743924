import 'date-fns';
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinesTable from './LinesTable'
import { DateRange } from 'react-date-range'
import GameService from '../../services/game.service'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  button: {
    width: '100%',
    textTransform: "none",
    whiteSpace: 'nowrap'
  },
  chart: {
    textAlign: 'center',
    height: '300px'
  },
  UpperProgressColor: {
    backgroundColor: '#dcb141',
  },
  DownProgressColor: {
    backgroundColor: '#6193bb',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableWrapper: {
    overflow: 'auto'
  },
  menuPaper: {
    maxHeight: '25%'
  },
  menuPaperMobile: {
    maxHeight: '400px'
  },
  ExcelButton: {
    textAlign: "right"
  }
}));

const UserHistory = () => {
  const classes = useStyles();
  const width = useWidth();

  const [seasonLoaded, setSeasonLoaded] = useState(false);
  const [leagueLoaded, setLeagueLoaded] = useState(false);
  const [tableLoaded, setTableLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [seasons, setSeasons] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [betDirection, setBetDirection] = useState(0);
  const [gameCnt, setGameCnt] = useState(1);
  const [successRate, setSuccessRate] = useState(0);

  const [analyzeLines, setAnalyzeLines] = useState([]);
  const [dateRange, setDateRange] = useState([{
    startDate: new Date('2021-09-01'),
    endDate: new Date(),
    key: 'selection',
  }]);
  const [tableData, setTableData] = useState([]);
  const [countData, setCountData] = useState([]);


  const [readyReq, setReadyReq] = useState(true);

  const { message, deleteSuccess, deleteFail } = useSelector(state => state.userhistory);
  const { lang } = useSelector(state => state.lang);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => {
    if (!tableLoaded || !message.data) return;
    setAlertOpen(true);
  }, [tableLoaded, deleteSuccess, deleteFail, message]);

  useEffect(() => { // SEASON
    GameService.getSeasons().then((data) => {
      setSeasons(data);
      if (data.length) {
        setSelectedSeason(data[0].id);
        setSeasonLoaded(true);
      }
      else
        setSeasonLoaded(false);
    },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  useEffect(() => { // LEAGUE
    if (seasonLoaded) {
      setLoading(true);
      setLeagueLoaded(false);
      GameService.getLeaguesByGameCnt({ season_id: selectedSeason, game_count: gameCnt, success_rate: successRate }).then((data) => { 
        setLeagues(data);
        setSelectedLeagues(data.map((league) => league.id));
        setLeagueLoaded(true);
      },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }, [selectedSeason, seasonLoaded, gameCnt, successRate]);

  useEffect(() => { // TABLE DATA
    if (!leagueLoaded) return;
    setLoading(true);
    setTableLoaded(false);
    const req = {
      season_id: selectedSeason,
      league_ids: selectedLeagues,
      start_date: dateRange[0].startDate,
      end_date: dateRange[0].endDate,
    };

    setReadyReq(false);
    console.log("req", req);
    GameService.getAnalyzeLines(req).then((data) => {
      console.log("data from server",data);
      // if(data.req_cnt != readyReq) return;
      setAnalyzeLines(data);
      setTableLoaded(true);
      setReadyReq(true);
    },
      (error) => {
        setReadyReq(true);
        console.log("error", error);
      }
    );
  }, [leagueLoaded, selectedSeason, selectedLeagues, dateRange, deleteSuccess]);

  useEffect(() => { // bet direction filter
    if (!tableLoaded) return;
    console.log("analyzeLine", analyzeLines)
    setLoading(true);
    setTableData(analyzeLines.filter((line) => betDirection === 0 ? true : line.bet_direction_val === betDirection));
    setLoading(false);
  }, [tableLoaded, analyzeLines, betDirection, gameCnt, successRate]);

  const leagueChanged = (e) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedLeagues(selectedLeagues.length === leagues.length ? [] : leagues.map((league) => league.id));
      return;
    }
    setSelectedLeagues(value);
  }

  const filterAndCount = (myArray) => {
    const leagueId = x => myArray[x].league_id;
    let hash = new Map();
    for (var i = 0; i < myArray.length; i++) {
      let key = leagueId(i);
      let item = myArray[i];
      let values = hash.get(key);
      if (values) values.push(item);
      else hash.set(key, [item]);
    }
    let ar = [];
    let count, win;
    hash.forEach(v => {
      count = 0; win = 0;
      v.forEach(i => {
        i.count = ++count; i.win = (i.win_lose === "WON" ? (++win) : win);
      });
      ar.push({ ...v[v.length - 1], success_rate: (win * 100 / count).toFixed(1), lose: count - win })
    }
    );
    return ar;
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat='yyyy-MM-dd'
                showDateDisplay={true}
                ranges={dateRange}
              />
            </div>
            <Button variant="contained" color="default" className={classes.button} onClick={() => setOpen(false)}>
              {lang.ca_label_ok || 'OK'}
            </Button>
          </div>
        </Fade>
      </Modal>

      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.result ? "success" : "error"}>
          {message.data}
        </Alert>
      </Snackbar>


      <Grid container className={classes.selecter} spacing={1}>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={110}>
                  {lang.hs_select_season || 'Select Season:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Select
                    value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'season',
                      id: 'id-season',
                    }}
                  >
                    {seasons.map((season) => (
                      <MenuItem
                        className={classes.option}
                        key={season.id}
                        value={season.id}
                      >
                        {season.name}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={115}>
                  {lang.hs_select_leagues || 'Select Leagues:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Select
                    MenuProps={{
                      classes: { paper: width === 'xs' || width === 'sm' ? classes.menuPaperMobile : classes.menuPaper },
                      getContentAnchorEl: null
                    }}
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    className={classes.width100}
                    multiple
                    value={selectedLeagues}
                    onChange={leagueChanged}
                    input={<Input />}
                    renderValue={(selected) => `${selected.length} ${lang.hs_select_leagueselected || 'Leagues selected'}`}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={leagues.length > 0 && selectedLeagues.length === leagues.length}
                        indeterminate={selectedLeagues.length > 0 && selectedLeagues.length < leagues.length}
                      />
                      <ListItemText
                        primary={lang.hs_select_selectall || 'Select All'}
                      />
                    </MenuItem>
                    {leagues.map((league) => (
                      <MenuItem key={league.id} value={league.id}>
                        <Checkbox checked={selectedLeagues.indexOf(league.id) > -1} />
                        <ListItemText primary={league.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={90}>
                  {lang.hs_picker_date || 'Select Date:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Input
                    className={classes.button}
                    onClick={() => setOpen(!open)}
                    value={`${dateRange[0].startDate.toISOString().slice(0, 10)} to ${dateRange[0].endDate.toISOString().slice(0, 10)}`}
                    onChange={(e) => e}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={140}>
                  {lang.hs_tablehead_betdirection || 'Bet Direction'}
                </Box>

                <Box width="100%" mr={1}>
                  <Select
                    value={betDirection}
                    onChange={(e) => setBetDirection(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'bet_direction',
                      id: 'id-bet_direction',
                    }}
                  >
                    {[
                      { label: "ALL", val: 0 },
                      { label: "UNDER", val: -1 },
                      { label: "OVER", val: 1 }
                    ].map((d) => (
                      <MenuItem
                        className={classes.option}
                        key={d.val}
                        value={d.val}
                      >
                        {d.label}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {lang.le_label_leaguemorethan || 'Leagues with more than '}
              <Input
                type="number"
                value={gameCnt}
                onChange={(e) => setGameCnt(e.target.value)}
                disabled={!readyReq}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step: 1,
                  min: 1,
                  max: 999,
                  style: { textAlign: 'center' }
                }}
              />
              {lang.le_label_unit_games || 'games'}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {lang.le_label_leaguemorethan || 'Leagues with more than '}
              <Input
                type="number"
                value={successRate}
                onChange={(e) => setSuccessRate(Number(e.target.value))}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step: 1,
                  min: 0,
                  max: 100,
                  style: { textAlign: 'center' }
                }}
              />
              {lang.le_label_unit_successrate || '%Success Rate'}
            </CardContent>
          </Card>
        </Grid>

      </Grid>

      {
        loading 
          ? <div style={{textAlign:'center'}}><CircularProgress style={{marginTop: 100}} disableShrink={true}/></div>
          : <>
            <Card className={classes.root} variant="outlined">
              <CardContent className={classes.tableWrapper}>
                <LinesTable tableData={tableData.filter(d => d.game_type_id === 0)} gameType={lang.ln_label_allgame || "All Games"} />
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent className={classes.tableWrapper}>
                <LinesTable tableData={tableData.filter(d => d.game_type_id === 5)} gameType={lang.ln_label_5game || "5 Games"} />
              </CardContent>
            </Card>

            <Card className={classes.root} variant="outlined">
              <CardContent className={classes.tableWrapper}>
                <LinesTable tableData={tableData.filter(d => d.game_type_id === 4)} gameType={lang.ln_label_4game || "4 Games"} />
              </CardContent>
            </Card>
          </>
      }


    </div>
  );
};

export default UserHistory;
