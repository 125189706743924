import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as CrowdIcon } from '../../assets/crowd.svg';
import ImgUpperArrow from '../../assets/Up.png';
import ImgDownArrow from '../../assets/Down.png';
import ProgressBar from '../custom/ProgressBar'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  textCenter: {
    textAlign: 'center'
  },
  progress: {
    marginTop: '30px',
  },
  table: {
    // minWidth: 200,
  },
  subtitle: {
    margin: theme.spacing(3),
    textAlign:'center',
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
    },
  },
  nodata: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '12px 6px',
  },
  marginAutoContainer: {
    width: "100%",
    display: 'flex',
  },
  marginAutoItem: {
    margin: 'auto',
		display: 'flex',
    alignItems: 'center'
  },
	inline: {
		display: 'inline',
		margin:theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      fontSize: '35px'
    },
	},	
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  UpperProgressColor: {
    backgroundColor: '#dcb141',
  },
  DownProgressColor: {
    backgroundColor: '#6193bb',
  },
  tablecell: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
  },
  tablehead: {
    fontSize: 16,
    padding: '16px 6px',
    borderBottom: '1px solid #e0e0e0',
  },
  tablebody: {
    padding: '12px 6px',
  }

}));


const RightSide = () => {
  const classes = useStyles();
  const width = useWidth();

  const { user: currentUser } = useSelector(state => state.auth);
  const { filtered_data, averages, line, nda, wisdom } = useSelector(state => state.game);
  const [upperCnt, setUpperCnt] = useState(0);
  const [isAdmin, setAdmin] = useState(false);

  const { lang } = useSelector(state => state.lang);

  useEffect(() => {
    console.log("filtered data", filtered_data);
    setUpperCnt(Object.keys(filtered_data).filter((key) => key.match(/F\d/)).filter(function(s) { return filtered_data[s] - line > 0; }).length);
  },[filtered_data, line]);

  useEffect(() => {
    if(currentUser !== null && currentUser.roles.includes("ROLE_ADMIN")) return setAdmin(true);
    setAdmin(false);
  },[currentUser]);
  const getMaxOrMin = (obj, isMax) => {
		return Number(obj[Object.keys(obj).filter((key) => key.match(/F[0-8]/)).reduce((a, b) => (Number(obj[a])*isMax > Number(obj[b])*isMax) ? a : b)]).toFixed(1)
	}

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
      <Grid container spacing={0}>
          {[
            {key:0, reg: /F[1-3]/},
            {key:1, reg: /F[4-6]/},
            {key:2, reg: /F[7-9]/}
          ].map((i, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Table className={classes.table} aria-label="caption table" >
                <TableBody>
                  <TableRow>
                    {
                      nda ? [1,2,3].map((j, index) => (
                        <TableCell align="center" key={index}>
                          <div className={classes.tablecell}>
                            <div className={classes.tablehead}>
                              {lang.ca_tablehead_analyze || 'Analyze'}&nbsp;{(width==='xs' || width==='md')&& (<br/>)}#{i.key + j}
                            </div>
                            <div className={classes.nodata}>
                              {lang.ca_label_nodata || 'No Data'}
                            </div>
                          </div>
                        </TableCell>
                      )) : Object.keys(filtered_data).map((key, index) => (
                        key.match(i.reg) ? (
                          <TableCell align="center" key={index} >
                            <div className={classes.tablecell}>
                              <div className={classes.tablehead}>
                                {lang.ca_tablehead_analyze || 'Analyze'}&nbsp;{(width==='xs' || width==='md')&& (<br/>)}#{key.slice(-1)}
                              </div>
                              {
                                (line !== "" && line !== 0) && (
                                  <div className={classes.tablebody}>
                                    {
                                      ((filtered_data[key] - line) > 0) ? (
                                        <img src={ImgUpperArrow} alt="upper" className={classes.imgIcon}/>
                                      ) : (
                                        <img src={ImgDownArrow} alt="down" className={classes.imgIcon}/>
                                      )
                                    }
                                    <br/>
                                    { (filtered_data[key] - line).toFixed(1) }
                                  </div>
                                )
                              }
                            </div>
                          </TableCell>
                          ) : null
                        )
                      ) 
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          ))}
          </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" className={classes.subtitle}>
                  {lang.ca_label_gamerange || 'Game Stats Range'}
                </Typography>
                <div className={classes.marginAutoContainer}>
                  <div className={classes.marginAutoItem}>
                    <Typography variant="h3" className={classes.inline}>
                      {nda ? 0 : getMaxOrMin(filtered_data, -1)}
                    </Typography>
                    <ArrowForwardIcon variant="h3"/>
                    <Typography variant="h3" className={classes.inline}>
                      {nda ? 0 : getMaxOrMin(filtered_data, 1)}
                    </Typography>
                  </div>
                </div>
                <Typography variant="h6" className={classes.textCenter}>
                  <Grid container spacing={2}>                  
                    {!nda && averages.map((d, i) => (
                      <Grid key={i} item xs={12} md={4}>
                        <h6>
                          { 
                            d.game_type_id === 0 ? lang.ln_label_allgame || 'All Games' : 
                            d.game_type_id === 4 ? lang.ln_label_4game   || '4 Games' : 
                            d.game_type_id === 5 ? lang.ln_label_5game   || '5 Games' : d.title
                          }
                        </h6>
                        {d.value}
                      </Grid>
                    ))}
                  </Grid>
                  {/* {lang.ca_label_average || 'Average'}: {nda ? 0 : filtered_data.F9} */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
           
          <Grid item xs={12} md={4}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" className={classes.subtitle}>
                  {lang.ca_label_analyzerdirections || 'Analyzer Directions'}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ProgressBar variant="determinate" className={classes.progress} value={nda || line === "" || line === 0 ? 0 : upperCnt} classes={{barColorPrimary: classes.UpperProgressColor}} />
                    <ProgressBar variant="determinate" className={classes.progress} value={nda || line === "" || line === 0 ? 0 : 9-upperCnt} classes={{barColorPrimary: classes.DownProgressColor}} />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.textCenter}>
                    <Typography variant="h3" component="h2" >
                      { nda || line === "" || line === 0 ? 0 : (Math.round((upperCnt > 4 ? upperCnt : (9-upperCnt))*100/9))}%
                    </Typography>
                    {
                      line !== "" && line !== 0 && (upperCnt > 4 ? (
                        <img src={ImgUpperArrow} alt="upper" className={classes.imgIcon}/>
                      ) : (
                        <img src={ImgDownArrow} alt="upper" className={classes.imgIcon}/>
                      ))
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" className={classes.subtitle}>
                  {lang.ca_label_wisdomofcrowd || 'Wisdome Of The Crowd'}
                </Typography>
                <Grid container spacing={3} className={classes.textCenter}>
                  <Grid item xs={12} sm={6} >
                    <SvgIcon style={{ fontSize: 100 }} ><CrowdIcon /></SvgIcon>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {
                      <div>
                        {isAdmin && !nda && (
                          <Typography variant="h3" component="h2" >
                            { wisdom.total === 0 ? 0 : Math.round((wisdom.upper > wisdom.total/2 ? wisdom.upper : (wisdom.total-wisdom.upper))*100/wisdom.total)}%
                          </Typography>
                          )
                        }
                        {
                          isAdmin && !nda && (wisdom.upper > wisdom.total/2 ? (
                            <img src={ImgUpperArrow} alt="upper" className={classes.imgIcon}/>
                          ) : (
                            <img src={ImgDownArrow} alt="upper" className={classes.imgIcon}/>
                          ))
                        }
                        {
                          (isAdmin || wisdom.total>=100) ? (
                            !nda && (
                              <Typography variant="h5" component="h2" >
                                { wisdom.upper > wisdom.total/2 ? wisdom.upper : (wisdom.total-wisdom.upper) } / { wisdom.total } 
                              </Typography>
                            )
                          ) : (
                            <Typography variant="h5" component="h2" >
                              {lang.ca_label_comingsoon || 'Coming Soon'}
                            </Typography>
                          )
                        }
                      </div>
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
  );
}

export default RightSide;