import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { 
  BarChart, 
  Bar, 
  // Cell, 
  XAxis, 
  YAxis, 
  // CartesianGrid, 
  Tooltip, 
  // Legend, 
  ResponsiveContainer 
} from 'recharts';

var tooltip;
var total;
const CustomTooltip = ({ active, payload }) => {
  const { lang } = useSelector(state => state.lang);
    if (!active || !tooltip)    return null
    for (const bar of payload)
      if (bar.dataKey === tooltip)
        return (
          <div style={{
            backgroundColor: 'white', 
            padding: 10, 
            border: "2px solid #1D8FC8"
            }}
          >
            {lang.hs_label_success  || 'Success'}: { bar.value } - { (bar.value*100/total).toFixed(1) }% 
          </div>
        );
    return null
}

const VerticalBar = ({tableData}) => {
  const [chartData, setChartData] = useState([]);
  
  useEffect(() => { 
    let chartTemp = [
      { name:'A1', Success:0 },
      { name:'A2', Success:0 },
      { name:'A3', Success:0 },
      { name:'A4', Success:0 },
      { name:'A5', Success:0 },
      { name:'A6', Success:0 },
      { name:'A7', Success:0 },
      { name:'A8', Success:0 },
      { name:'A9', Success:0 }
    ]
    tableData.forEach(line => chartTemp.forEach((e, index)=>line[chartTemp[index]['name'].toLowerCase()] ? chartTemp[index].Success++: e));
    console.log("chart Result", chartTemp);
    setChartData(chartTemp);
  }, [tableData]);
  
  return (
    <ResponsiveContainer width="100%" height="100%"> 
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 10,
          right: 5,
          left: -25,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} domain={[0, 'dataMax + 2']}/>
        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip/>} />
        {/* <Legend /> */}
        <Bar dataKey="Success" fill="#1D8FC8" onMouseOver={ () => {tooltip="Success"; total=tableData.length} }/>
      </BarChart>
   </ResponsiveContainer> 
  );
}

export default VerticalBar;