import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  progress: {
		height: 25,
		borderRadius: 2,
		marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
		width: '100%',
		backgroundColor: 'white',
	},
}));

export default function ProgressBar(props) {
	const classes = useStyles();
	
	let MIN = 0;
	let MAX = 9;
	// Function to normalise the values (MIN / MAX could be integrated)
	const normalise = value => (value - MIN) * 100 / (MAX - MIN);
	
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} value={normalise(props.value)} className={classes.progress} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="body1" color="textSecondary">{`${Math.round(
					props.value,
				)}`}</Typography>
			</Box>
		</Box>
	);
}

