import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getFormulas } from "../../actions/game";
import GameService from "../../services/game.service";
import TemporaryLineTable from "./TemporaryLineTable";
import AdminDecisionTable from "./AdminDecisionTable"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import date from 'date-and-time';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import TeamTable from './TeamTable';
// import ExcelButton from './ExcelButton';
import { Button } from '@material-ui/core';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) => ({
  topContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    margin: 'auto'
  },
  topContainerMobile: {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center'
  },
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  exportButton: {
    margin: '10px'
  },
  gameSelect: {
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      fontSize: '11px'
    },
  },
  table: {
    minWidth: 650,
  },
  marginAutoContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: 'flex',
  },
  marginAutoItem: {
    margin: 'auto'
  },
  selecter: {
    marginTop: theme.spacing(4),
  },
  width100: {
    width: '100%',
    textAlign: 'center'
  },
  autocomplete:{paddingTop: '4px'},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 2),
    textAlign: 'center'
  },
  okButton:{
    marginTop: theme.spacing(1),
  },
  lastUpdated:{
    width: 150,
    textAlign: 'center'
  },
  mybetbtn: {
    width: 170
  },
  myBetMobile: {
    marginTop: theme.spacing(1),
  }
}));
const Calculator = (props) => {
  const classes = useStyles();
  const [games, setGames] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [leagueId, setLeagueId] = useState(-1);
  const [analyzeGame, setAnalyzeGame] = useState({});
  const [myBet, setMyBet] = useState([]);
  const [home_team_id, setHomeTeamId] = useState(0);
  const [away_team_id, setAwayTeamId] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("");
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openMine, setOpenMine] = useState(false);

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  const { lang } = useSelector(state => state.lang);
  const width = useWidth();

  useEffect(() => {
    GameService.getAnalyzeGameLeague().then((data) => {
        setLeagues(data);
      },
      (error) => {
        console.log("error", error);
      }
    );
    GameService.getLastUpdate().then((data) => {
      console.log("Last UPdate: ", new Date(data.script_ran).toString());
      setLastUpdate(new Date(data.script_ran).toString());
    },
    (error) => {
      console.log("error", error);
    }
  );
  }, []);

  useEffect(() => {
    if(leagueId !== 0)
      GameService.getAnalyzeGameByLeagueID({league_id:leagueId}).then((data) => {
          console.log("Gamges::::", data);
          setGames(data);
          if(data.length){
            setAnalyzeGame(data[0]);
            setHomeTeamId(data[0].home_team_id);
            setAwayTeamId(data[0].away_team_id);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
  }, [leagueId]);

  useEffect(() => {
    if(!analyzeGame.id) return;
    
    const curGame = games.find(element => element.id === Number(analyzeGame.id));
    console.log('curGame', curGame);
    setHomeTeamId(curGame ? curGame.home_team_id : 0);
    setAwayTeamId(curGame ? curGame.away_team_id : 0);

    dispatch(getFormulas(analyzeGame.id))
      .then(() => {
      })
      .catch(() => {
        console.log("fail to dispatch");
      });
  },[analyzeGame, games, dispatch]);

  const handleMyDecisionClose = (r) => {
    setOpenMine(false);
  };

  const handleMyDecisionOpen = () => {
    setOpenMine(true);
    GameService.getMyTemporaryAnalyzeLine().then((data) => {
        setMyBet(data);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  const handleAdminDecisionClose = (r) => {
    setOpenAdmin(false);
  };

  const handleAdminDecisionOpen = () => {
    setOpenAdmin(true);
    // GameService.getMyTemporaryAnalyzeLine().then((data) => {
    //     setMyBet(data);
    //   },
    //   (error) => {
    //     console.log("error", error);
    //   }
    // );
  }

	return (
		<div>
      <div className={width !== 'xs' && width !== 'sm' ? classes.topContainer: classes.topContainerMobile} >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Card className={classes.root} variant="outlined" >
              <CardContent >

                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    {lang.ca_select_league || 'Please choose a League'}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      MenuProps={{
                        getContentAnchorEl: null
                      }}
                      value={leagueId}
                      onChange={(e) => setLeagueId(e.target.value)}
                      className={classes.width100}
                      labelId="league"
                      id="id-league"
                    >
                      <MenuItem 
                        className={classes.option}
                        key={-1}
                        value={-1}
                      >
                        {lang.ca_select_allleague || 'ALL LEAGUES'}
                      </MenuItem>
                      { leagues && (leagues.map((lg, index) => (
                        <MenuItem 
                          className={classes.option}
                          key={index}
                          value={lg.id}
                        >
                          {lg.name}
                        </MenuItem>
                      )))}
                    </Select>
                  </Grid>
                </Grid>
                
              </CardContent>
            </Card>  
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card className={classes.root} variant="outlined" >
              <CardContent >

                <Grid container>
                  <Grid item xs={12} sm={12} md={3}>
                    {lang.ca_select_game || 'Please choose a Game'}
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Autocomplete
                      options={games}
                      id="clear-on-escape"
                      value={analyzeGame}
                      className={classes.autocomplete}
                      isOptionEqualToValue={(option, value) => option && option.game_name }
                      disableClearable
                      getOptionLabel={
                        (game) => !game.game_name ? 'UnReadable' : 
                        game.game_name.split(' - ').length === 3 && leagueId !== -1 ? 
                          `${date.format(new Date(`${game.game_name.split(' - ')[0]} UTC+02`), 'YYYY-MM-DD HH:mm')} - ${game.game_name.split(' - ')[2]}` : 
                          `${date.format(new Date(`${game.game_name.split(' - ')[0]} UTC+02`), 'YYYY-MM-DD HH:mm')} - ${game.game_name.split(' - ')[1]} - ${game.game_name.split(' - ')[2]}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                      onChange={(event, newValue) => {
                        newValue && setAnalyzeGame(newValue);
                      }}
                      // renderOption={(props, option) =>  (
                      //     <span {...props} style={{ /** here will be style for selection of my decision */ }}>
                      //       {option.game_name}
                      //     </span>
                      //   )
                      // }
                    />
                  </Grid>
                </Grid>
                
              </CardContent>
            </Card>  
          </Grid>
        </Grid>
        <div className={classes.lastUpdated}>{lang.ca_label_lastupdated || 'Last Updated'}<br/>
          {lastUpdate && lastUpdate.slice(16,24)}
        </div>
        {currentUser && currentUser.roles.includes("ROLE_ADMIN") && width !== 'xs' && width !== 'sm' && (
          // <div className={classes.exportButton}>
          //   <ExcelButton data={games} />
          // </div>
          <Button variant="outlined" onClick={handleAdminDecisionOpen}>
            {lang.ca_button_admindecision || 'Admin Decisions'}
          </Button>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openAdmin}
          onClose={handleAdminDecisionClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAdmin}>
            <div className={classes.paper}>
              <h3>{lang.ca_button_admindecision || 'Admin Decisions'}</h3>
                <div style={{maxHeight:600, minHeight:100, minWidth:600, display:'flex', overflow: 'auto' }}>
                  <AdminDecisionTable/>
                </div>
              <Button variant="contained" color="default" className={classes.okButton} onClick={()=>handleAdminDecisionClose(true)}>
                {lang.ca_label_ok || 'OK'}
              </Button>
            </div>
          </Fade>
        </Modal>

        <Button variant="outlined" /*className={classes.mybetbtn}*/ onClick={handleMyDecisionOpen}>
          {lang.ca_button_mydecision || 'My Decisions'}
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openMine}
          onClose={handleMyDecisionClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openMine}>
            <div className={classes.paper}>
              <h3>{lang.ca_button_mydecision || 'My Decisions'}</h3>
                <div style={{maxHeight:360, overflow: 'auto' }}>
                  {width !== 'xs' && width !== 'sm'?(
                    <TemporaryLineTable data={myBet}/>
                  ):(
                    <div>
                      {myBet.map((row, index) => (
                        <Card variant="outlined" className={classes.myBetMobile}>
                          <CardContent >
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={2}><strong>{row.game_name}</strong></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">{lang.ca_tablehead_direction || 'Direction'}</TableCell>
                                  <TableCell align="right">{row.user_analyze_direction}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">{lang.ca_tablehead_gametype || 'Game Type'}</TableCell>
                                  <TableCell align="right">{row.game_type}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">{lang.ca_tablehead_bettype || 'Bet Type'}</TableCell>
                                  <TableCell align="right">{row.analyze_type}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">{lang.ca_tablehead_difference || 'Difference'}</TableCell>
                                  <TableCell align="right">{row.average}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">{lang.ca_tablehead_betline || 'Bet Line'}</TableCell>
                                  <TableCell align="right">{row.analyze_line}</TableCell>
                                </TableRow>
                                
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  )}
                </div>
              
              <Button variant="contained" color="default" className={classes.okButton} onClick={()=>handleMyDecisionClose(true)}>
                {lang.ca_label_ok || 'OK'}  
              </Button>
            </div>
          </Fade>
        </Modal>
      </div>       
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4} md={3}>
          <LeftSide />
				</Grid>
				<Grid item xs={12} sm={8} md={9}>
					<RightSide /> 
				</Grid>
			</Grid>
      
      {/* bottom side two table */}
      <Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
          <TeamTable team_id={home_team_id} analyze_game={analyzeGame}/>
				</Grid>
				<Grid item xs={12} sm={6}>
          <TeamTable team_id={away_team_id} analyze_game={analyzeGame}/>
				</Grid>
			</Grid>
		</div>
	);
}
export default Calculator;