import 'date-fns';
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StackedBarChart from '../custom/StackedBarChart'
import SuccessRateBar from '../custom/SuccessRateBar'
import LeagueTable from './LeagueTable'

import GameService from '../../services/game.service'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  button: {
    width: '100%',
    textTransform: "none",
    whiteSpace: 'nowrap'
  },
  chart: {
    textAlign: 'center',
    height: '300px'
  },
  UpperProgressColor: {
    backgroundColor: '#dcb141',
  },
  DownProgressColor: {
    backgroundColor: '#6193bb',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableWrapper: {
    overflow: 'auto'
  },
  menuPaper: {
    maxHeight: '25%'
  },
  menuPaperMobile: {
    maxHeight: '400px'
  }
}));

const GamesToBet = () => {
  const classes = useStyles();
  const width = useWidth();

  const [seasonLoaded, setSeasonLoaded] = useState(false);
  const [tableLoaded, setTableLoaded] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");

  const [analyzeLines, setAnalyzeLines] = useState([]);

  const [games, setGames] = useState(1);
  const [successRate, setSuccessRate] = useState(0);
  const [a9difference, setA9difference] = useState(0);
  const [analyzeType, setAnalyzeType] = useState(1);
  const [betDirection, setBetDirection] = useState(0);
  const [gameType, setGameType] = useState(0);
  const [filteredLeagues, setFilteredLeagues] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [tempData, setTempData] = useState([]);

  const { message, deleteSuccess, deleteFail } = useSelector(state => state.userhistory);
  const { lang } = useSelector(state => state.lang);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => { // SEASON
    GameService.getSeasons().then((data) => {
      setSeasons(data);
      if (data.length) {
        setSelectedSeason(data[0].id);
        setSeasonLoaded(true);
      }
      else
        setSeasonLoaded(false);
    },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  useEffect(() => { // TABLE DATA
    if (!seasonLoaded) return;
    GameService.getAnalyzeLinesBySeason({ season_id: selectedSeason }).then((data) => {
      console.log("getAnalyzeLinesBySeason", data);

      setAnalyzeLines(data);
      setTableLoaded(true);
    },
      (error) => {
        console.log("error", error);
      }
    );
  }, [selectedSeason, seasonLoaded]);

  useEffect(() => { // ADD FILTER (minimum_games, minimum_successrate)
    setFilteredLeagues(filterAndCount(analyzeLines
      .filter(g => g.game_type_id == gameType)
      .filter(a => a.analyze_type_id == analyzeType)
    )
      .filter(v => v.count >= games && v.success_rate >= successRate));
  }, [games, successRate, analyzeLines, gameType, analyzeType]);

  useEffect(() => { // when filteredLeagues change, send request to get temporaryLine by Array of leagues_ids 
    if (filteredLeagues.length > 0) {
      console.log("league_ids", filteredLeagues.map(l => l.count));
      GameService.getTemporaryLineForGamesBet({
        league_ids: filteredLeagues.map(l => l.league_id)
      }).then((data) => {
        setTempData(data);
      })
    }
    else
      setTempData([]);
  }, [filteredLeagues]);

  useEffect(() => {
    console.log('tempData', tempData);
    setFinalArray(tempData
      .filter(t => Math.abs(t.F9_points) > a9difference)
      .filter(g => g.game_type == gameType)
      .filter(a => a.analyze_type == analyzeType)
      .filter(b => betDirection === 0 ? true : (b.user_analyze_direction === betDirection)));
  }, [tempData, a9difference, gameType, analyzeType, betDirection]);

  useEffect(() => {
    if (!tableLoaded || !message.data) return;
    setAlertOpen(true);
  }, [tableLoaded, deleteSuccess, deleteFail, message]);

  const filterAndCount = (myArray) => {
    const leagueId = x => myArray[x].league_id;
    let hash = new Map();
    for (var i = 0; i < myArray.length; i++) {
      let key = leagueId(i);
      let item = myArray[i];
      let values = hash.get(key);
      if (values) values.push(item);
      else hash.set(key, [item]);
    }
    let ar = [];
    let count, win;
    hash.forEach(v => {
      count = 0; win = 0;
      v.forEach(i => {
        i.count = ++count; i.win = (i.win_lose === "WON" ? (++win) : win);
      });
      ar.push({ ...v[v.length - 1], success_rate: (win * 100 / count).toFixed(1), lose: count - win })
    }
    );
    console.log("filtered: ", ar);
    return ar;
  }

  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.result ? "success" : "error"}>
          {message.data}
        </Alert>
      </Snackbar>

      <Grid container className={classes.selecter} spacing={1}>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={110}>
                  {lang.hs_select_season || 'Select Season:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Select
                    value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'season',
                      id: 'id-season',
                    }}
                  >
                    {seasons.map((season) => (
                      <MenuItem
                        className={classes.option}
                        key={season.id}
                        value={season.id}
                      >
                        {season.name}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {lang.ca_select_minidiff_line || 'Minimum Difference from line'}
              <Input
                type="number"
                value={a9difference}
                onChange={(e) => setA9difference(Number(e.target.value))}
                inputProps={{
                  name: 'a9diff',
                  id: 'id-a9diff',
                  step: 1,
                  min: 0,
                  max: 100,
                  style: { textAlign: 'center' }
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container className={classes.selecter}>
                <Grid item xs={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {lang.ca_select_analyzetype || 'Please Choose a Bet'}
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Select
                    native
                    value={analyzeType}
                    onChange={(e) => setAnalyzeType(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'analyze_type',
                      id: 'id-analyze-type',
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7].map((at) => (
                      <option
                        className={classes.option}
                        key={at}
                        value={at}
                      >
                        {
                          at === 1 ? lang.ca_analyzetype_1 || 'Total Points' :
                            at === 2 ? lang.ca_analyzetype_2 || 'Second Half' :
                              at === 3 ? lang.ca_analyzetype_3 || 'First Half' :
                                at === 4 ? lang.ca_analyzetype_4 || 'Forth Quarter' :
                                  at === 5 ? lang.ca_analyzetype_5 || 'Third Quarter' :
                                    at === 6 ? lang.ca_analyzetype_6 || 'Second Quarter' :
                                      at === 7 ? lang.ca_analyzetype_7 || 'First Quarter' : ''
                        }
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Grid container className={classes.selecter}>
                <Grid item xs={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {lang.ca_select_gametype || 'Please Choose #Game'}
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <Select
                    value={gameType}
                    onChange={(e) => setGameType(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'game_type',
                      id: 'id-gmae_type',
                    }}
                  >
                    {[
                      { label: lang.ln_label_allgame || "All Games", val: 0 },
                      { label: lang.ln_label_5game || "5 Games", val: 5 },
                      { label: lang.ln_label_4game || "4 Games", val: 4 }
                    ].map((d) => (
                      <MenuItem
                        className={classes.option}
                        key={d.val}
                        value={d.val}
                      >
                        {d.label}
                      </MenuItem >
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1} >
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              {lang.le_label_leaguemorethan || 'Leagues with more than '}
              <Input
                type="number"
                value={games}
                onChange={(e) => setGames(e.target.value)}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step: 1,
                  min: 1,
                  max: 999,
                  style: { textAlign: 'center' }
                }}
              />
              {lang.le_label_unit_games || 'games'}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              {lang.le_label_leaguemorethan || 'Leagues with more than '}
              <Input
                type="number"
                value={successRate}
                onChange={(e) => setSuccessRate(Number(e.target.value))}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step: 1,
                  min: 0,
                  max: 100,
                  style: { textAlign: 'center' }
                }}
              />
              {lang.le_label_unit_successrate || '%Success Rate'}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={140}>
                  {lang.hs_tablehead_betdirection || 'Bet Direction'}
                </Box>

                <Box width="100%" mr={1}>
                  <Select
                    value={betDirection}
                    onChange={(e) => setBetDirection(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'bet_direction',
                      id: 'id-bet_direction',
                    }}
                  >
                    {[
                      { label: "ALL", val: 0 },
                      { label: "UNDER", val: -1 },
                      { label: "OVER", val: 1 }
                    ].map((d) => (
                      <MenuItem
                        className={classes.option}
                        key={d.val}
                        value={d.val}
                      >
                        {d.label}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

      </Grid>

      <Card className={classes.root} variant="outlined">
        <CardContent >
          <LeagueTable tableData={finalArray} />
        </CardContent>
      </Card>

    </div>
  );
};

export default GamesToBet;
