import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import date from 'date-and-time';
import { visuallyHidden } from '@mui/utils';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import GameService from "../../services/game.service";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const useStyles = makeStyles((theme) => ({
	root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  won: {
    color: 'green',
    fontWeight: 700,
  },
  lose: {
    color: 'red',
    fontWeight: 700,
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  iconbutton:{
    padding: "4px 8px"
  },
  alignRight:{
    textAlign: 'right',
    marginRight:10
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { lang } = useSelector(state => state.lang);

  const headCells = [
    {
      id: 'game_name',
      numeric: false,
      disablePadding: true,
      label: lang.ca_tablehead_gamename || 'Game'
    },
    {
      id: 'user_analyze_direction',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_direction || 'Direction'
    },
    {
      id: 'average',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_difference || 'Difference'
    },
    {
      id: 'analyze_line',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_betline || 'Bet Line'
    },
    {
      id: 'official_bet',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_officialbet || 'Official Bet'
    },
    {
      id: 'money',
      numeric: true,
      disablePadding: false,
      label: lang.ca_tablehead_money || 'Money'
    },
    {
      id: 'path',
      numeric: false,
      disablePadding: false,
      label: lang.ca_tablehead_path || 'Path'
    },
    {
      id: 'result',
      numeric: false,
      disablePadding: false,
      label: lang.ca_tablehead_result || 'Result'
    },
  ];
  
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableHeader}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomTableCell = ({ row, name, onChange, type }) => {
  const classes = useStyles();
  const { lang } = useSelector(state => state.lang);
  const { isEditMode } = row;
  return (
    <TableCell align="center" className={classes.tableCell}>
      {isEditMode ? (
        type === 'select' ? (
          <Select
            MenuProps={{
              getContentAnchorEl: null
            }}
            name={name}
            value={row[name] !== null ? row[name] : ''} 
            onChange={e => onChange(e, row)}
            className={classes.input}
          >
            <MenuItem className={classes.option} value={true}> {lang.ca_label_win || 'WIN'} </MenuItem>
            <MenuItem className={classes.option} value={false}> {lang.ca_label_lose || 'LOSE'} </MenuItem>
          </Select>
        ):(<Input
          value={row[name] !== null ? row[name] : ''}
          name={name}
          type={type}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />)
      ) : (
        name === 'result' ? (row[name] !== null ? row[name] ? (lang.ca_label_win || 'WIN') : (lang.ca_label_lose || 'LOSE') : '') 
        : row[name]
      )}
    </TableCell>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default function EnhancedTable() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [previous, setPrevious] = useState({});
  const [rowChanged, setRowChanged] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState({message:"", type:"success"});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('game_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [showHidden, setShowHidden] = useState(false);

  const { lang } = useSelector(state => state.lang);

  useEffect(() => {
    // setLoading(true);
    GameService.getAdminDirection().then((data) => {
        console.log("getAdminDirection:", data);
        // setRows(data.map(row => {
        //   if (row.game_name !== null) {
        //     const date = (new Date(row.game_name.slice(0, 10))).toString().slice(0,10);
        //     return { ...row, game_name : date + row.game_name.slice(11, -1)};
        //   }
        //   return row;
        // }));
        setRows(data);
        setLoading(false);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [rowChanged]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    GameService.deleteAdminDirection(id).then((data) => {
        setRowChanged(!rowChanged);
        setAlertOpen(true);
        setAlert(data);
      },
      (error) => {
        console.log("error", error);
        setAlert({message:"Delete failed!", type: "error"});
      }
    );
  }

  const onToggleEditMode = (id, isRevert = false) => {
    const editRow = rows.find(row => row.id === id);
    if(editRow.isEditMode !== null && editRow.isEditMode && !isRevert) {
      GameService.saveAdminDirection(editRow).then((data) => {
        console.log("rerender direction", data);
          setRowChanged(!rowChanged);
          setAlertOpen(true);
          setAlert(data);
          setPrevious(state => {
            delete state[id];
            return state;
          });
        },
        (error) => {
          console.log("onToggleEditMode error", error);
          setRowChanged(!rowChanged);
          setAlertOpen(true);
          setAlert({message:"Edit failed!", type: "error"});
          setPrevious(state => {
            delete state[id];
            return state;
          });
        }
      );
    }
    if(isRevert) return;
    setRows(rows.map(row => {
      if (row.id === id) {
        return { ...row, isEditMode: !row.isEditMode };
      }
      return row;
    }));
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    setRows(rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    }));
  };

  const onRevert = id => {
    console.log("revert content", previous);
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? { ...previous[id], isEditMode: !row.isEditMode } : { ...row, isEditMode: !row.isEditMode };
      }
      return row;
    });
    console.log('reverted row',newRows);
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id, true);
  };

  const onSwitchHide = id => {
    const hideRow = rows.find(row => row.id === id);

    GameService.saveAdminDirection({
      ...hideRow, 
      hide_row:!hideRow.hide_row
    }).then((data) => {
        console.log("rerender direction", data);
        setRowChanged(!rowChanged);
        setAlertOpen(true);
        setAlert(data);
      },
      (error) => {
        console.log("onToggleEditMode error", error);
        setRowChanged(!rowChanged);
        setAlertOpen(true);
        setAlert({message:"Edit failed!", type: "error"});
      }
    );
  }

  const handleDialogOpen = (id) => {
    setDialogOpen(true);
    const row = rows.find(row => row.id === id);
    setDeleteRow(row);
  };

  const handleDialogClose = (result) => {
    setDialogOpen(false);
    if(result){
      handleDelete(deleteRow.id);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
    {loading ? (
      <div style={{margin:'auto'}}><span className="spinner-border spinner-border-lg"></span></div>
      
    ) : (
      <Box sx={{ width: '100%', minWidth: 750 }}>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Requested"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lang.ca_label_deleteexplain || 'You are going to delete'} 
            <strong>{deleteRow.game_name}</strong>.
            {lang.ca_label_deleteconfirm || 'Are you sure?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>{lang.ca_label_cancel || 'Cancel'}</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            {lang.ca_label_ok || 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
       
      <Paper sx={{ width: '100%', mb: 2}}>
        <div className={classes.alignRight}>
          <FormControlLabel style={{margin: 0}} control={
            <Switch 
              color="primary"
              checked={showHidden}
              onChange={() => setShowHidden(!showHidden)}
            /> } 
            label={lang.ca_label_showhidden || 'Show Hidden'} 
          />
        </div>
        <TableContainer>
        { rows.filter(row => showHidden || !row.hide_row).length ?
          <Table
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.filter(row => showHidden || !row.hide_row).length}
            />
            <TableBody>
              {(rowsPerPage > 0 ?  
                stableSort(rows, getComparator(order, orderBy)).filter(row => showHidden || !row.hide_row)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : 
                stableSort(rows, getComparator(order, orderBy)).filter(row => showHidden || !row.hide_row)
              )
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell component="th" scope="row">
                        {`${date.format(new Date(`${row.game_name.split(' - ')[0]} UTC+02`), 'YYYY-MM-DD HH:mm')} - ${row.game_name.split(' - ')[1]} - ${row.game_name.split(' - ')[2]}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.user_analyze_direction === 1 ? 'OVER' : 'UNDER'}</StyledTableCell>
                      <StyledTableCell align="center">{row.average}</StyledTableCell>
                      <StyledTableCell align="center">{row.analyze_line}</StyledTableCell>
                      <CustomTableCell {...{ row, name: "official_bet", onChange, type:"number" }} />
                      <CustomTableCell {...{ row, name: "money", onChange, type:"number" }} />
                      <CustomTableCell {...{ row, name: "path", onChange, type:"text" }} />
                      <CustomTableCell {...{ row, name: "result", onChange, type:"select" }} />
                      <TableCell>
                        {row.isEditMode ? (
                          <>
                            <IconButton
                              aria-label="done"
                              className={classes.iconbutton}
                              onClick={() => onToggleEditMode(row.id)}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="revert"
                              className={classes.iconbutton}
                              onClick={() => onRevert(row.id)}
                            >
                              <RevertIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              aria-label="edit"
                              className={classes.iconbutton}
                              onClick={() => onToggleEditMode(row.id)}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              aria-label="hide"
                              className={classes.iconbutton}
                              onClick={() => onSwitchHide(row.id)}
                            >
                              {row.hide_row ? <VisibilityOutlinedIcon/> : <VisibilityOffIcon/> }
                            </IconButton>
                            
                            <IconButton 
                              aria-label="delete" 
                              className={classes.iconbutton}
                              onClick={()=>handleDialogOpen(row.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                        
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          : 
          <div>
            {lang.ca_label_nodata || 'No Data'}
          </div>
         }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, { label: 'All', value: -1 }]}
          component="div"
          count={rows.filter(row => showHidden || !row.hide_row).length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    
    </Box>
    )}
    </>
  );
}
