import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const MyBox = ({title, value}) => {
  
  return (
    <Card variant="outlined">
        <CardContent style={{textAlign: 'center', color: '#1D8FC8', padding:12, border: "2px solid #1D8FC8"}}>
            <h5 style={{height:50}}>{title}</h5>
            <h3>{value}</h3>
        </CardContent>
    </Card>
  );
}

export default MyBox;