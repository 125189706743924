import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
				backgroundColor: 'white', 
				padding: 10, 
				border: "2px solid #1D8FC8",
        borderRadius: 6,
				}}
			>
				<h5>{payload[0].payload.league_name}</h5> 
				Games: {payload[0].payload.count} <br/>
				Won: {payload[0].payload.win} <br/>
				Lose: {payload[0].payload.lose} <br/>
				Success: {payload[0].payload.success_rate}%
			</div>
    );
  }

  return null;
};

const CustomizedAxisTick = ({ x, y, payload }) => {

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
        style={{fontSize:13}}
      >
        {payload.value}
      </text>
    </g>
  );
};

export default function App({data}) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: -5,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="league_name" name="League Name" interval={0} height={200} tick={<CustomizedAxisTick />}/>
        <YAxis name="Won/Lose"/>
        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip/>} />
        <Bar dataKey="win" stackId="a" fill="#098029"/>
        <Bar dataKey="lose" stackId="a" fill="#CE3930" />
      </BarChart>
    </ResponsiveContainer> 
  );
}
