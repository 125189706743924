import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import ImgUpperArrow from '../../assets/Up.png';
import ImgDownArrow from '../../assets/Down.png';
import LineArrow from '../../assets/286492665right-arrow-blue.gif';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Chip from '@material-ui/core/Chip';

import GameService from "../../services/game.service";
import { getWisdomOfCrowd } from "../../actions/game";
import { FORMULA_FILTERED, LINE_CHANGED, NDA_CHANGED, AVERAGES } from "../../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%',
    textAlign: 'center'
  },
  option: {
    textAlign: 'center'
  },
  table: {
    minWidth: 650,
  },
	makeDecision: {
		marginTop: theme.spacing(4),
	},
  marginAutoContainer: {
    width: "100%",
    display: 'flex',
  },
  marginAutoItem: {
    margin: 'auto',
		display: 'flex',
    alignItems: 'center'
  },
	inline: {
		display: 'inline',
		margin:theme.spacing(4),
	},
  selecter: {
    marginTop: theme.spacing(4),
  },
	button: {
		margin: theme.spacing(1),
	},
	imgIcon: {
		width: "30px",
		height: "30px"
	},
  lineArrow: {
		width: "45px",
		height: "30px"
	},
  line: {
    display: 'flex'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center'
  },
  savedLineContainer: {
    margin: 5
  },
  myBetButtonContainer: {
    textAlign: 'center'
  }
}));


const LeftSide = () => {
  const classes = useStyles();
	const dispatch = useDispatch();

	const [state, setStates] = useState({
    analyze_type_id: 0,
    game_type_id: 0,
  });

	const [analyzeTypes, setAnalyzeTypes] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [line, setLine] = useState("");
  const [direction, setDirection] = useState(0);
  const [action, setAction] = useState(true);//true: 'save', false: 'delete'

	const { user } = useSelector(state => state.auth);
	const { formulas, filtered_data, nda, wisdom} = useSelector(state => state.game);
  const { lang } = useSelector(state => state.lang);

	useEffect(() => {
    GameService.getAnalyzeTypeNames().then((data) => {
			setAnalyzeTypes(data);
			if(data.length)
				setStates(state => {
          return {...state,
            analyze_type_id: data[0].id,
				}});
      },
      (error) => {
        console.log("error", error);
      }
    );
    GameService.getGameTypeNames().then((data) => {
			setGameTypes(data);
			if(data.length)
				setStates(state => {
          return {...state,
            game_type_id: data[0].id,
				}});
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [formulas]);

	useEffect(() => {
		let r = formulas.filter(data => (data.analyze_type_id === state.analyze_type_id && data.game_type_id === state.game_type_id))[0];

    let averages = [];
    !!gameTypes.length && gameTypes.map(t => {
      const f = formulas.filter(data => (data.analyze_type_id === state.analyze_type_id && data.game_type_id === t.id))[0];
      averages.push({title: t.name, game_type_id: t.id, value: f ? f.F9 : 0});
      return true;
    });
		if(!r || Object.keys(r).filter((key) => key.match(/F\d/)).some((e) => { return r[e] < 0})) {
			r = {};
		}
    const nda_temp = Object.keys(r).length === 0;
    if(!nda_temp) setLine(r.F9)
    
		dispatch({
			type: NDA_CHANGED,
			payload: nda_temp,
		});
    dispatch({
			type: FORMULA_FILTERED,
			payload: r
		});
		dispatch({
			type: AVERAGES,
			payload: averages
		});
  }, [state, formulas, gameTypes, dispatch]);

	useEffect(() => {
  }, [nda, dispatch]);

  useEffect(() => {
		dispatch({
			type: LINE_CHANGED,
			payload: line,
		});
  }, [line, dispatch]);

	useEffect(() => {
    if(Object.keys(filtered_data).length)
      dispatch(getWisdomOfCrowd(filtered_data))
				.then(() => {
				})
				.catch(() => {
					console.log("fail to dispatch");
				});
  },[filtered_data, dispatch]);

  const handleClose = (r) => {
    setOpen(false);
    
    if(r && direction !== 0){
      if(action) {
        const req = {...filtered_data, direction, user_id: user.id, line: line};
        GameService.saveTemporaryAnalyzeLine(req).then((data) => {
          dispatch(getWisdomOfCrowd(filtered_data))
            .then(() => {
            })
            .catch(() => {
              console.log("fail to dispatch");
            });
        });
      } else {
        GameService.deleteTemporaryAnalyzeLine(direction > 0 ? wisdom.mine_up.id : wisdom.mine_down.id).then((data) => {
          dispatch(getWisdomOfCrowd(filtered_data))
            .then(() => {
            })
            .catch(() => {
              console.log("fail to dispatch");
            });
        });
      }
    }
  };

	const handleChange = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);

    if(name === 'line') return setLine(value);

    setStates({
      ...state,
      [name]: value,
    });
  };

	const handleClick = (d, a) => {
    setAction(a)
    setDirection(d);
    
    if((wisdom.mine_up && d === 1) || (wisdom.mine_down && d === -1) || !a) {
      setOpen(true);
    }
    else {
      const req = {...filtered_data, direction:d, user_id: user.id, line: line};
    
      GameService.saveTemporaryAnalyzeLine(req).then((data) => {
        dispatch(getWisdomOfCrowd(filtered_data))
          .then(() => {
          })
          .catch(() => {
            console.log("fail to dispatch");
          });
      });
    }
  };

	return (
    <Card className={classes.root} variant="outlined" >
			<CardContent >

				<Grid container className={classes.selecter}>
					<Grid item xs={12} md={12} lg={6}>
            {lang.ca_select_analyzetype || 'Please Choose a Bet'}
					</Grid>
					<Grid item xs={12} md={12} lg={6}>
						<Select
							native
							value={state.analyze_type_id}
							onChange={handleChange}
							disabled={nda}
							className={classes.width100}
							inputProps={{
								name: 'analyze_type_id',
								id: 'id-analyze-type',
							}}
						>
							{ analyzeTypes && (analyzeTypes.map((analyzeType) => (
								<option 
                  className={classes.option}
									key={analyzeType.id}
									value={analyzeType.id}
								>
                  { 
                    analyzeType.id === 1 ? lang.ca_analyzetype_1 || 'Total Points' : 
                    analyzeType.id === 2 ? lang.ca_analyzetype_2 || 'Second Half' : 
                    analyzeType.id === 3 ? lang.ca_analyzetype_3 || 'First Half' : 
                    analyzeType.id === 4 ? lang.ca_analyzetype_4 || 'Forth Quarter' :
                    analyzeType.id === 5 ? lang.ca_analyzetype_5 || 'Third Quarter' : 
                    analyzeType.id === 6 ? lang.ca_analyzetype_6 || 'Second Quarter' :
                    analyzeType.id === 7 ? lang.ca_analyzetype_7 || 'First Quarter' : analyzeType.name
                  }
								</option>
							)))}
						</Select>
					</Grid>
				</Grid>

				<Grid container className={classes.selecter}>
					<Grid item xs={12} md={12} lg={6}>
            {lang.ca_select_gametype || 'Please Choose #Game'}
					</Grid>
					<Grid item xs={12} md={12} lg={6}>
						<Select
							native
							value={state.game_type_id}
							onChange={handleChange}
							disabled={nda}
							className={classes.width100}
							inputProps={{
								name: 'game_type_id',
								id: 'id-game-type',
							}}
						>
							{ gameTypes && (gameTypes.map((gameType) => (
								<option
                  className={classes.option}
									key={gameType.id}
									value={gameType.id}
								>
									{ 
                    gameType.id === 0 ? lang.ln_label_allgame || 'All Games' : 
                    gameType.id === 4 ? lang.ln_label_4game   || '4 Games' : 
                    gameType.id === 5 ? lang.ln_label_5game   || '5 Games' : gameType.name
                  }
								</option>
							)))}
						</Select>
					</Grid>
				</Grid>

				<Grid container className={classes.selecter}>
					<Grid item xs={12} md={12} lg={6}>
            {lang.ca_input_line || 'Please Fill the Line'}
            {/* <img src={LineArrow} alt="upper" className={classes.lineArrow}/> */}
					</Grid>
					<Grid item xs={12} md={12} lg={6} className={classes.line}>
          <img src={LineArrow} alt="upper" className={classes.lineArrow}/>
						<Input 
							type="number"
							className={classes.width100}
							value={line}
							onChange={handleChange}
							disabled={nda}
							inputProps={{
								name: 'line',
								id: 'id-line',
                step:0.5,
								min: 0, 
								max: 999,
                style: { textAlign: 'center' } }}
            />
            
					</Grid>
				</Grid>
				
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              {action ? (
                <div>
                  <h2 id="transition-modal-title">{lang.ca_modaltitle_lineexist || 'Your line already exists!'}</h2>
                  <p id="transition-modal-description">{lang.ca_modalcontent_lineexist || 'Would you like to replace it?'}</p>
                </div>
              ) : (
                <div>
                  <h2 id="transition-modal-title">{lang.ca_modaltitle_deleteline || 'You are going to delete your line'}</h2>
                  <p id="transition-modal-description">{lang.ca_modalcontent_deleteline || 'Do you want to delete your saved line?'}</p>
                </div>
              )}
              <Button variant="contained" color="default" className={classes.button} onClick={()=>handleClose(false)}>
                {lang.ca_label_cancel || 'Cancel'}
              </Button>
              <Button variant="contained" color="default" className={classes.button} onClick={()=>handleClose(true)}>
                {lang.ca_label_ok || 'OK'}
              </Button>
            </div>
          </Fade>
        </Modal>

				{/* <div className={classes.makeDecision}>
					<Typography variant="h6" component="h2" align="center" >
            {lang.ca_label_savedecision || 'Save your decision'}
					</Typography>
					<div className={classes.marginAutoContainer}>
						<div className={classes.marginAutoItem}>
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
									<div className={classes.myBetButtonContainer}>
										<Button variant="contained" color="default" className={classes.button} onClick={()=>handleClick(1, true)} disabled={nda}>
											<img src={ImgUpperArrow} alt="upper" className={classes.imgIcon}/>
										</Button>
                    {wisdom.mine_up && !nda &&(
                      <div className={classes.savedLineContainer}>
                        <Chip label={wisdom.mine_up.analyze_line} deleteIcon={<HighlightOffIcon />} onDelete={()=>handleClick(1, false)}/>
                      </div>
                    )}
									</div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
									<div className={classes.myBetButtonContainer}>
										<Button variant="contained" color="default" className={classes.button} onClick={()=>handleClick(-1, true)} disabled={nda}>
											<img src={ImgDownArrow} alt="upper" className={classes.imgIcon}/>
										</Button>
                    {wisdom.mine_down && !nda && (
                      <div className={classes.savedLineContainer}>
                        <Chip label={wisdom.mine_down.analyze_line} deleteIcon={<HighlightOffIcon />} onDelete={()=>handleClick(-1, false)}/>
                      </div>
                    )}
									</div>
                </Grid>
							</Grid>
						</div>
					</div>
				</div> */}
			</CardContent>
		</Card>
  );
}

export default LeftSide;