import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import GameService from "../../services/game.service";
import date from 'date-and-time';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  bold: {
    fontWeight: 'bold'
  },
  win: {
    backgroundColor: '#098029',
    color: 'white',
    padding: '5px',
    borderRadius: '3px',
    width: '30px'
  },
  lose: {
    backgroundColor: '#CE3930',
    color: 'white',
    padding: '5px',
    borderRadius: '3px',
    width: '30px'
  },
  win_lose: {
    width: '50px'
  }
}));

const TeamTable = (props) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [analyzeType, setAnalyzeType] = useState("");
  const [teamData, setTeamData] = useState({});

  const { filtered_data,nda } = useSelector(state => state.game);
  const { lang } = useSelector(state => state.lang);

  useEffect(() => {
    let temp = ""
    switch(filtered_data.analyze_type_id) {
      case 1: temp = 'total_points'; break;
      case 2: temp = 'h2_points'; break;
      case 3: temp = 'h1_points'; break;
      case 4: temp = 'q4_points'; break;
      case 5: temp = 'q3_points'; break;
      case 6: temp = 'q2_points'; break;
      case 7: temp = 'q1_points'; break;
      default: temp = 'total_points'; break;
    }
    setAnalyzeType(temp);
  },[filtered_data]);

  useEffect(() => {
    if(props.team_id)
      GameService.getLastFiveGame({
        team_id: props.team_id, 
        league_id: props.analyze_game.league_id, 
        season_id: props.analyze_game.season_id 
      }).then((data) => {
          setRows(data.result ? data.result : []);
          setTeamData(data.team ? data.team : {});
        },
        (error) => {
          console.log("error", error);
        }
      );
  }, [props.team_id, props.analyze_game]);

	return (
    <div>
      <Typography variant="h6">
        {`${lang.ca_tabletitle_gameofteam || 'Last Games of'} ${teamData.name}`} 
      </Typography>
      <Card className={classes.root} variant="outlined" >
        <CardContent >
         
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.bold}>{lang.ca_tablehead_gamedate || 'Game Date'}</TableCell>
                <TableCell align="center" className={classes.bold}>{lang.ca_tablehead_hometeam || 'Home Team'}</TableCell>
                <TableCell align="center" className={classes.bold}>{lang.ca_tablehead_awayteam || 'Away Team'}</TableCell>
                <TableCell align="center" className={classes.bold}>{lang.ca_tablehead_points || 'Points'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!nda && rows.map((row) => (
                <TableRow
                  key={row.id}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {date.format(new Date(row.game_date), 'YYYY-MM-DD')}
                  </TableCell>
                  <TableCell align="center" className={row.home_team_id === props.team_id ? classes.bold:''}>{row.home_team_name}</TableCell>
                  <TableCell align="center" className={row.away_team_id === props.team_id ? classes.bold:''}>{row.away_team_name}</TableCell>
                  <TableCell align="center">
                    <span className={row.home_team_id === props.team_id ? classes.bold:''}>{row[`ht_${analyzeType}`]}</span>
                      : 
                    <span className={row.away_team_id === props.team_id ? classes.bold:''}>{row[`at_${analyzeType}`]}</span></TableCell>
                  <TableCell align="center" className={classes.win_lose}>
                    {row.away_team_id === props.team_id ^ row.ht_total_points > row.at_total_points ? 
                      (<div className={classes.win}>W</div>) 
                      : 
                      (<div className={classes.lose}>L</div>) 
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
		
	);
}
export default TeamTable;