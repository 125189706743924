import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function BasicTable(props) {
  const { lang } = useSelector(state => state.lang);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{lang.ca_tablehead_gamename || 'Game'}</StyledTableCell>
            <StyledTableCell align="center">{lang.ca_tablehead_direction || 'Direction'}</StyledTableCell>
            <StyledTableCell align="center">{lang.ca_tablehead_gametype || 'Game Type'}</StyledTableCell>
            <StyledTableCell align="center">{lang.ca_tablehead_bettype || 'Bet Type'}</StyledTableCell>
            <StyledTableCell align="center">{lang.ca_tablehead_difference || 'Difference'}</StyledTableCell>
            <StyledTableCell align="center">{lang.ca_tablehead_betline || 'Bet Line'}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.map((row, index) => (
            <StyledTableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.game_name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.user_analyze_direction === "OVER" ? (lang.ca_label_over || "OVER") : (lang.ca_label_under || "UNDER")}</StyledTableCell>
              <StyledTableCell align="center">{row.game_type}</StyledTableCell>
              <StyledTableCell align="center">{row.analyze_type}</StyledTableCell>
              <StyledTableCell align="center">{row.average}</StyledTableCell>
              <StyledTableCell align="center">{row.analyze_line}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}