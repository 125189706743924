export const SET_LANG_SUCCESS = "SET_LANG_SUCCESS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATEPROFILE_SUCCESS = "UPDATEPROFILE_SUCCESS";
export const UPDATEPROFILE_FAIL = "UPDATEPROFILE_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const RESET_PWD_READY = "RESET_PWD_READY";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const FORMULA_CHANGED = "FORMULA_CHANGED";
export const FORMULA_FILTERED = "FORMULA_FILTERED";
export const AVERAGES = "AVERAGES";
export const LINE_CHANGED = "LINE_CHANGED";
export const NDA_CHANGED = "NDA_CHANGED";
export const WISDOM_CHANGED = "WISDOM_CHANGED";

export const ANALYZE_LINE_MESSAGE = "ANALYZE_LINE_MESSAGE";
export const ANALYZE_LINE_DELETE_SUCCESS = "ANALYZE_LINE_DELETE_SUCCESS";
export const ANALYZE_LINE_DELETE_FAIL = "ANALYZE_LINE_DELETE_FAIL";