import 'date-fns';
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScatterChart from '../custom/ScatterChart'
import StackedBarChart from '../custom/StackedBarChart'
import SuccessRateBar from '../custom/SuccessRateBar'
import LeagueTable from './LeagueTable'

import MyBox from '../custom/MyBox'
import MyPieChart from '../custom/MyPieChart'
import { DateRange } from 'react-date-range'
import GameService from '../../services/game.service'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) => ({
	root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  button: {
    width: '100%',
    textTransform: "none",
    whiteSpace: 'nowrap'
  },
  chart: {
    textAlign: 'center',
    height: '300px'
  },
  UpperProgressColor: {
    backgroundColor: '#dcb141',
  },
  DownProgressColor: {
    backgroundColor: '#6193bb',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableWrapper: {
    overflow: 'auto'
  },
  menuPaper: {
    maxHeight: '25%'
  },
  menuPaperMobile: {
    maxHeight: '400px'
  }
}));

const LeagueReport = () => {
  const classes = useStyles();
  const width = useWidth();
  
  const [seasonLoaded, setSeasonLoaded] = useState(false);
  const [leagueLoaded, setLeagueLoaded] = useState(false);
  const [tableLoaded, setTableLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  
  const [seasons, setSeasons] = useState([]);
  const [leagues, setLeagues] = useState([]);  
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedLeagues, setSelectedLeagues] = useState([]);


  const [searchStr, setSearchStr] = useState("");
  const [tableData, setTableData] = useState([]);

  const [analyzeLines, setAnalyzeLines] = useState([]); 
  const [dateRange, setDateRange] = useState([{
    startDate: new Date('2021-09-01'),
    endDate:  new Date(),
    key: 'selection',
  }]); 
  const [games, setGames] = useState(1);
  const [successRate, setSuccessRate] = useState(0);
  const [gameType, setGameType] = useState(0);
  const [filteredLeagues, setFilteredLeagues] = useState([]);

  const { message, deleteSuccess, deleteFail } = useSelector(state => state.userhistory);
  const { lang } = useSelector(state => state.lang);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => { 
    if(!tableLoaded || !message.data) return;
    setAlertOpen(true);
  }, [tableLoaded, deleteSuccess, deleteFail, message]);

  useEffect(() => { // SEASON
    GameService.getSeasons().then((data) => {
      setSeasons(data);
      if(data.length) {
        setSelectedSeason(data[0].id);
        setSeasonLoaded(true);
      }
      else
        setSeasonLoaded(false);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  useEffect(() => { // LEAGUE
    if(seasonLoaded) {
      GameService.getLeagues({season_id: selectedSeason}).then((data) => {
        setLeagues(data);
        
        setSelectedLeagues(data.map((league)=>league.id));
        setLeagueLoaded(true);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }, [selectedSeason, seasonLoaded]);

  useEffect(() => { // TABLE DATA
    if(!leagueLoaded) return;
    const req = {
      season_id: selectedSeason,
      league_ids: selectedLeagues,
      start_date: dateRange[0].startDate,
      end_date: dateRange[0].endDate,
    };

    GameService.getAnalyzeLines(req).then((data) => {
      console.log(data);
      setAnalyzeLines(data);
      setTableLoaded(true);
      
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [ leagueLoaded, selectedSeason, selectedLeagues, dateRange, deleteSuccess ]);

  useEffect(() => { // SEARCH STRING
    if(!tableLoaded) return;
    console.log("gameType", gameType);
    console.log("analyzeLines", analyzeLines);
    setTableData(analyzeLines.filter(
      a => a.game_type_id === gameType
    ).filter(
      (line) => Object.keys(line).some(
        i=>line[i] && line[i].toString().toLowerCase().includes(searchStr.toLowerCase())
      )))
  }, [ tableLoaded, analyzeLines, searchStr, gameType ]);

  useEffect(() => { // LEAGUE
    const data = filterAndCount(tableData).filter(v => v.count >= games && v.success_rate >= successRate);
    console.log("filtered ", data);
    setFilteredLeagues(data);
  }, [games, successRate, tableData]);

  const leagueChanged = (e) => {
    const value = e.target.value;
    
    if (value[value.length - 1] === "all") {
      setSelectedLeagues(selectedLeagues.length === leagues.length ? [] : leagues.map((league)=>league.id));
      return;
    }
    setSelectedLeagues(value);
  }

  const filterAndCount = (myArray) => {
    const leagueId = x => myArray[x].league_id;
    let hash = new Map();
    for (var i = 0; i < myArray.length; i++) {
      let key = leagueId(i);
      let item = myArray[i];
      let values = hash.get(key);
      if (values) values.push(item);
      else hash.set(key, [item]);
    }
    let ar = [];
    let count, win;
    hash.forEach(v => {
      count = 0; win = 0; 
      v.forEach(i => {
        i.count = ++count;i.win = (i.win_lose === "WON" ? (++win) : win);
      }); 
      ar.push({...v[v.length - 1], success_rate: (win*100/count).toFixed(1), lose: count-win})
      }
    );
    console.log("filtered: ", ar);
    return ar;
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={()=>setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat='yyyy-MM-dd'
                showDateDisplay={true}
                ranges={dateRange}
              />
            </div>
            <Button variant="contained" color="default" className={classes.button} onClick={()=>setOpen(false)}>
              {lang.ca_label_ok || 'OK'}
            </Button>
          </div>
        </Fade>
      </Modal>

      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.result ? "success" : "error"}>
          {message.data}
        </Alert>
      </Snackbar>

      <div>
        <Grid container className={classes.selecter} spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <div style={{width: '80%', margin: 'auto'}}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MyBox 
                    title={lang.hs_label_noleagues  || 'No. of Leagues'}
                    value={filteredLeagues.length}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyBox 
                    title={lang.hs_label_nogames   || "No. of Games"}
                    value={filteredLeagues.map(item => item.count).reduce((a, b) => a + b, 0)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MyBox 
                    title={lang.hs_label_nowin  || "No. of WIN"}
                    value={filteredLeagues.map(item => item.win).reduce((a, b) => a + b, 0)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyBox 
                    title={lang.hs_label_nolose  || "No. of LOSE"}
                    value={filteredLeagues.map(item => item.lose).reduce((a, b) => a + b, 0)}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <div className={classes.chart}>
              <h4>{lang.le_label_leagues	|| 'Leagues'}</h4>
              <ScatterChart data={filteredLeagues} />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <div className={classes.chart}>
              <h4>{lang.le_label_successrate	|| 'Success Rate'}</h4>
              <MyPieChart chartData={ 
                [
                  { name:'WIN', value: filteredLeagues.map(item => item.win).reduce((a, b) => a + b, 0) },
                  { name:'LOSE', value: filteredLeagues.map(item => item.lose).reduce((a, b) => a + b, 0) },
                ]}  
              />
            </div>
          </Grid>
        </Grid>
        
      </div>
      <Grid container className={classes.selecter} spacing={1}>
      
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={110}>
                  {lang.hs_select_season || 'Select Season:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Select
                    value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'season',
                      id: 'id-season',
                    }}
                  >
                    { seasons.map((season) => (
                      <MenuItem 
                        className={classes.option}
                        key={season.id}
                        value={season.id}
                      >
                        {season.name}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={115}>
                  {lang.hs_select_leagues || 'Select Leagues:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Select
                    MenuProps={{
                      classes: { paper: width === 'xs' || width === 'sm' ? classes.menuPaperMobile : classes.menuPaper },
                      getContentAnchorEl: null
                    }}
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    className={classes.width100}
                    multiple
                    value={selectedLeagues}
                    onChange={leagueChanged}
                    input={<Input />}
                    renderValue={(selected) => `${selected.length} ${lang.hs_select_leagueselected  || 'Leagues selected'}`}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={leagues.length > 0 && selectedLeagues.length === leagues.length}
                        indeterminate={selectedLeagues.length > 0 && selectedLeagues.length < leagues.length}
                      />
                      <ListItemText
                        primary={lang.hs_select_selectall || 'Select All'}
                      />
                    </MenuItem>
                    {leagues.map((league) => (
                      <MenuItem key={league.id} value={league.id}>
                        <Checkbox checked={selectedLeagues.indexOf(league.id) > -1} />
                        <ListItemText primary={league.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={90}>
                  {lang.hs_picker_date || 'Select Date:'}
                </Box>
                <Box width="100%" mr={1}>
                  <Input 
                    className={classes.button}
                    onClick={()=>setOpen(!open)}
                    value={`${dateRange[0].startDate.toISOString().slice(0, 10)} to ${dateRange[0].endDate.toISOString().slice(0, 10)}`}
                    onChange={(e)=> e}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box minWidth={70}>
                  {lang.hs_input_search || 'Search:'}
                </Box>

                <Box width="100%" mr={1}>
                  <Input 
                    type="text"
                    className={classes.width100}
                    value={searchStr}
                    onChange={(e)=> setSearchStr(e.target.value)}
                    inputProps={{
                      name: 'searchStr',
                      id: 'id-searchStr',
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

      </Grid>

      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={4} style={{textAlign:'center'}}>
            {lang.le_label_leaguemorethan	 || 'Leagues with more than '}
              <Input 
                type="number"
                value={games}
                onChange={(e) => setGames(e.target.value)}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step: 1,
                  min: 1, 
                  max: 999,
                  style: { textAlign: 'center' } }}
              />
              {lang.le_label_unit_games	|| 'games'}
            </Grid>
            <Grid item xs={12} sm={4}>
              {lang.le_label_leaguemorethan	 || 'Leagues with more than '}
              <Input 
                type="number"
                value={successRate}
                onChange={(e) => setSuccessRate(Number(e.target.value))}
                inputProps={{
                  name: 'games',
                  id: 'id-games',
                  step:1,
                  min: 0, 
                  max: 100,
                  style: { textAlign: 'center' } }}
              />
              {lang.le_label_unit_successrate	|| '%Success Rate'}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <Box minWidth={140}>
                  {lang.hs_tablehead_gametype	 || 'Game Type '}
                </Box>

                <Box width="100%" mr={1}>
                  <Select
                    value={gameType}
                    onChange={(e) => setGameType(e.target.value)}
                    className={classes.width100}
                    inputProps={{
                      name: 'game_type',
                      id: 'id-gmae_type',
                    }}
                  >
                    { [
                        {label: lang.ln_label_allgame || "All Games",  val: 0}, 
                        {label: lang.ln_label_5game 	|| "5 Games",    val: 5},
                        {label: lang.ln_label_4game 	|| "4 Games",    val: 4}
                      ].map((d) => (
                      <MenuItem 
                        className={classes.option}
                        key={d.val}
                        value={d.val}
                      >
                        {d.label}
                      </MenuItem >
                    ))}
                  </Select>
                </Box>
              </Box>
            </Grid>
          </Grid>
     
        </CardContent>
      </Card>
        
      <Grid container spacing={3} >
        <Grid item xs={12} md={6}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{height:420, textAlign:'center'}}>
              <h4>
                {lang.le_label_no_of	|| 'No. of'}&nbsp;
                <span style={{color:'#098029'}}>{lang.ca_label_win	|| 'WIN'}</span>/<span style={{color:'#CE3930'}}>{lang.ca_label_lose	|| 'LOSE'}</span>&nbsp;
                {lang.le_label_game_by_league	|| 'Games by leagues'}
              </h4>
              <StackedBarChart data={filteredLeagues}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.root} variant="outlined">
            <CardContent style={{height:420,  textAlign:'center'}}>
              <h4>{lang.le_label_successrate_by_leagues	|| 'Success Rate by leagues'}</h4>
              <SuccessRateBar data={filteredLeagues}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Card className={classes.root} variant="outlined">
        <CardContent >
          <LeagueTable tableData={filteredLeagues}/>
        </CardContent>
      </Card>
     
    </div>
  );
};

export default LeagueReport;
