import React from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer,
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => {

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
        style={{fontSize:13}}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
				backgroundColor: 'white', 
				padding: 10, 
				border: "2px solid #1D8FC8",
        borderRadius: 6,
				}}
			>
				<h5>{payload[0].payload.league_name}</h5> 
				Games: {payload[0].payload.count} <br/>
				Won: {payload[0].payload.win} <br/>
				Lose: {payload[0].payload.lose} <br/>
				Success: {payload[0].payload.success_rate}%
			</div>
    );
  }

  return null;
};

const VerticalBar = ({data}) => {
  return (
    <ResponsiveContainer width="100%" height="100%"> 
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 5,
          left: -5,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="league_name" name="League Name" interval={0} height={200} tick={<CustomizedAxisTick />}/>
        <YAxis dataKey="success_rate" name="Success Rate" domain={[0, 100]} unit="%"/>
        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip/>} />
        {/* <Legend /> */}
        <Bar dataKey="success_rate" fill="#1D8FC8" />
      </BarChart>
   </ResponsiveContainer> 
  );
}

export default VerticalBar;