import * as React from 'react';
import { useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 0px",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  body: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)"
    }
  }
}))(TableCell);

export default function LinesTable({ tableData, gameType }) {
  const { lang } = useSelector(state => state.lang);
  // console.log(gameType, tableData);
  return (
    tableData.length > 0 && <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center"></StyledTableCell>
            {[
              'All Rows',
              'A9 +/- 1',
              'A9 +/- 2',
              'A9 +/- 3',
              'A9 +/- 4',
              'A9 +/- 5'
            ].map((col, index) => (
              <StyledTableCell key={index} align="center" colSpan={4}>
                {col}
              </StyledTableCell>
            ))}
          </TableRow>
          <TableRow>
            <StyledTableCell>{gameType}</StyledTableCell>

            {[0, 1, 2, 3, 4, 5].map((col) => (
              <React.Fragment key={col}>
                <StyledTableCell align="center">{lang.ca_label_win || 'WIN'}</StyledTableCell>
                <StyledTableCell align="center">{lang.ca_label_lose || 'LOSE'}</StyledTableCell>
                <StyledTableCell align="center">%</StyledTableCell>
                <StyledTableCell align="center">{lang.ca_label_profit || 'Profit'}</StyledTableCell>
              </React.Fragment >
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            { label: '-5', value: 154 },
            { label: '-4', value: 160 },
            { label: '-3', value: 166 },
            { label: '-2', value: 173 },
            { label: '-1', value: 181 },
            { label: '', value: 188 },
            { label: '+1', value: 195 },
            { label: '+2', value: 203 },
            { label: '+3', value: 210 },
            { label: '+4', value: 223 },
            { label: '+5', value: 230 },
          ].map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell>Line {row.label}</StyledTableCell>

              {[0, 1, 2, 3, 4, 5].map((col) => {
                const cellData = tableData.filter(v => col === 0 ? true : Math.abs(v.difference) > col); //&& Math.abs(v.bet_line - v.game_result) > Number(row.label)
                const total = cellData.length;
                let win, lose;

                if (Number(row.label) < 0) {// here we need to check only the lose rows
                  lose = cellData.filter(f => !f.win_lose_flag).filter(v => Math.abs(v.bet_line - v.game_result) > Math.abs(row.label)).length;
                  win = total - lose;
                }
                else if (Number(row.label) > 0) {
                  win = cellData.filter(f => f.win_lose_flag).filter(v => Math.abs(v.bet_line - v.game_result) > Math.abs(row.label)).length;
                  lose = total - win;
                }
                else {
                  win = cellData.filter(f => f.win_lose_flag).length;
                  lose = total - win;
                }
                const profit = win * row.value - total * 100;
                return (
                  <React.Fragment key={col}>
                    <StyledTableCell align="center">{win}</StyledTableCell>
                    <StyledTableCell align="center">{lose}</StyledTableCell>
                    <StyledTableCell align="center">{(total ? win * 100 / total : 0).toFixed(1)} %</StyledTableCell>
                    <StyledTableCell align="center" style={profit < 0 ? { color: '#CE3930' } : { color: '#098029' }}>{profit}</StyledTableCell>
                  </React.Fragment >
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
