import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import UpSucceed from '../../assets/UpSucceed.png';
import DownSucceed from '../../assets/DownSucceed.png';
import UpFail from '../../assets/UpFail.png';
import DownFail from '../../assets/DownFail.png';
import { visuallyHidden } from '@mui/utils';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { deleteAnalyzeLine } from "../../actions/game";
import date from 'date-and-time';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const components = {
  UpSucceed, 
  DownSucceed, 
  UpFail,
  DownFail,
};

const useStyles = makeStyles((theme) => ({
	root: {
    marginTop: theme.spacing(2),
  },
  width100: {
    width: '100%'
  },
  won: {
    color: 'green',
    fontWeight: 700,
  },
  lose: {
    color: 'red',
    fontWeight: 700,
  },
  imgIcon: {
    width: "30px",
    height: "30px"
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  tableCell: {
    padding: "6px 0px !important"
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } =
    props;
  const { lang } = useSelector(state => state.lang);


  const headCells = [
    {
      id: 'game_name',
      numeric: false,
      disablePadding: true,
      label: lang.hs_tablehead_gamename || 'Game'
    },
    {
      id: 'game_date',
      numeric: false,
      disablePadding: false,
      label: lang.hs_tablehead_gamedate || 'Date'
    },
    {
      id: 'analyze_type',
      numeric: false,
      disablePadding: false,
      label: lang.hs_tablehead_analyzetype || 'Analyze Type'
    },
    {
      id: 'game_type',
      numeric: false,
      disablePadding: false,
      label: lang.hs_tablehead_gametype || 'Game Type'
    },
    {
      id: 'bet_direction',
      numeric: false,
      disablePadding: false,
      label: lang.hs_tablehead_betdirection || 'Bet Direction'
    },
    {
      id: 'bet_line',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_betline || 'Bet Line'
    },
    {
      id: 'difference',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_difference || 'Difference'
    },
    {
      id: 'game_result',
      numeric: true,
      disablePadding: false,
      label: lang.hs_tablehead_gameresult || 'Game Result'
    },
    {
      id: 'win_lose',
      numeric: false,
      disablePadding: false,
      label: lang.hs_tablehead_winlose || 'Win/Lose'
    },
    {
      id: 'a1',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}1`
    },
    {
      id: 'a2',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}2`
    },
    {
      id: 'a3',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}3`
    },
    {
      id: 'a4',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}4`
    },
    {
      id: 'a5',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}5`
    },
    {
      id: 'a6',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}6`
    },
    {
      id: 'a7',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}7`
    },
    {
      id: 'a8',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}8`
    },
    {
      id: 'a9',
      numeric: true,
      disablePadding: false,
      label: `${lang.hs_tablehead_a || 'A'}9`
    },
  ];
    
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            className={classes.tableCell}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableHeader}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default function EnhancedTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('game_date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const { lang } = useSelector(state => state.lang);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    console.log("id", id);
    dispatch(deleteAnalyzeLine(id))
      .then(() => {
      })
      .catch(() => {
        console.log("fail to dispatch");
      });
  };

  const handleDialogOpen = (id) => {
    setDialogOpen(true);
    const row = props.tableData.find(row => row.key_id === id);
    console.log("delete row", row);
    setDeleteRow(row);
  };

  const handleDialogClose = (result) => {
    setDialogOpen(false);
    if(result){
      console.log("handleDelete", deleteRow.key_id);
      handleDelete(deleteRow.key_id);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.tableData.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Requested"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lang.ca_label_deleteexplain || 'You are going to delete'}
            <strong> {deleteRow.game_name ? deleteRow.game_name : ''} </strong>.
            {lang.ca_label_deleteconfirm || 'Are you sure?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            {lang.ca_label_ok || 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.tableData.length}
            />
            <TableBody>
              {(rowsPerPage > 0 ?  
                stableSort(props.tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : 
                stableSort(props.tableData, getComparator(order, orderBy))
              )
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        padding="none"
                        style={{minWidth:350}}
                      >
                        {row.game_name}
                      </TableCell>
                      <StyledTableCell align="center" style={{minWidth:150}}>{date.format(new Date(row.game_date), 'YYYY-MM-DD')}</StyledTableCell>
                      <StyledTableCell align="center" >
                        { 
                          row.analyze_type_id === 1 ? lang.ca_analyzetype_1 || 'Total Points' : 
                          row.analyze_type_id === 2 ? lang.ca_analyzetype_2 || 'Second Half' : 
                          row.analyze_type_id === 3 ? lang.ca_analyzetype_3 || 'First Half' : 
                          row.analyze_type_id === 4 ? lang.ca_analyzetype_4 || 'Forth Quarter' :
                          row.analyze_type_id === 5 ? lang.ca_analyzetype_5 || 'Third Quarter' : 
                          row.analyze_type_id === 6 ? lang.ca_analyzetype_6 || 'Second Quarter' :
                          row.analyze_type_id === 7 ? lang.ca_analyzetype_7 || 'First Quarter' : row.analyze_type
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center" >
                        { 
                          row.game_type_id === 0 ? lang.ln_label_allgame || 'All Games' : 
                          row.game_type_id === 4 ? lang.ln_label_4game   || '4 Games' : 
                          row.game_type_id === 5 ? lang.ln_label_5game   || '5 Games' : row.game_type
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{maxWidth:100}}>
                        {row.bet_direction_val === 1 ? (lang.ca_label_over || 'OVER') : (lang.ca_label_under || 'UNDER')}
                      </StyledTableCell>
                      <StyledTableCell align="center" >{row.bet_line}</StyledTableCell>
                      <StyledTableCell align="center" >{row.difference}</StyledTableCell>
                      <StyledTableCell align="center" >{row.game_result}</StyledTableCell>
                      <StyledTableCell align="center">
                        <span className={row.win_lose === 'WON' ? classes.won : classes.lose}>
                          {row.win_lose ==='WON' ? (lang.ca_label_win || 'WIN') : (lang.ca_label_lose || 'LOSE')}
                        </span>
                      </StyledTableCell>
                      {
                        Object.keys(row).filter((key) => key.match(/a\d/)).map(ax => (
                          <StyledTableCell align="center" key={ax}>
                            <img src={
                              components[
                                (row[ax] ^ row.bet_direction === 'OVER' ^ row.win_lose === 'WON' ? 'Up' : 'Down') + 
                                (row[ax] ? 'Succeed' : 'Fail')
                              ]} 
                              alt="upper" 
                              className={classes.imgIcon}
                            />
                          </StyledTableCell>
                        ))
                      }
                      <StyledTableCell align="center">
                        <IconButton aria-label="delete" onClick={()=>handleDialogOpen(row.key_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, { label: 'All', value: -1 }]}
          component="div"
          count={props.tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
}
