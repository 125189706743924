import React from "react";
import { useSelector } from "react-redux";
import ReactExport from "react-export-excel";
import Button from '@material-ui/core/Button';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelButton = ({data}) => {
	const { lang } = useSelector(state => state.lang);

	return (
		<div>
			<ExcelFile element={<Button variant="outlined">{lang.hs_button_export || 'Export'}</Button>}>
				<ExcelSheet data={data.map(d => {
					d.result_betline = (d.game_result-d.bet_line);
					d.bet_line = Number(d.bet_line);
					d.difference = Number(d.difference);
					return d;
				})} name="Game Average">
					<ExcelColumn label="Game Name" value="game_name"/>
					<ExcelColumn label="Game Date" value="game_date"/>
					<ExcelColumn label="Analyze Type" value="analyze_type"/>
					<ExcelColumn label="Game Type" value="game_type"/>
					<ExcelColumn label="Bet Direction" value="bet_direction"/>
					<ExcelColumn label="Bet line" value="bet_line"/>
					<ExcelColumn label="Difference" value="difference"/>
					<ExcelColumn label="Result" value="game_result"/>
					<ExcelColumn label="Result-BetLine" value="result_betline"/>
					<ExcelColumn label="Win/Lose" value="win_lose"/>
					<ExcelColumn label="A1" value="a1"/>
					<ExcelColumn label="A2" value="a2"/>
					<ExcelColumn label="A3" value="a3"/>
					<ExcelColumn label="A4" value="a4"/>
					<ExcelColumn label="A5" value="a5"/>
					<ExcelColumn label="A6" value="a6"/>
					<ExcelColumn label="A7" value="a7"/>
					<ExcelColumn label="A8" value="a8"/>
					<ExcelColumn label="A9" value="a9"/>
				</ExcelSheet>
			</ExcelFile>
		</div>
	);
}

export default ExcelButton;